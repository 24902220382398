<template>
	<v-card>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>
							<v-row style="margin-top: 10px">
								<v-col cols="12" style="display: inline-flex;">
									<v-chip
										style="margin: 0px"
										small
										:color="'info'"
									>
										{{ "Ingreso QR" }} </v-chip
									><!-- <span>
										<v-switch label="Salida" v-model="Exit">
										</v-switch>
									</span> -->
								</v-col>
							</v-row>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center" style="margin:auto" v-if="objCalifornian !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<h2><b>{{objCalifornian.ClfName}} - </b>
									
									<v-chip
										large
										class="ma-2"
										variant="outlined"
										:color="objCalifornian.inCalifornianStateColor"
										>{{
											objCalifornian.inParihuelaUsage
										}}
									</v-chip>
									<v-chip
										large
										:color="objCalifornian.inCalifornianStateColor"
										><b>{{
											objCalifornian.inCalifornianStateName
										}}</b>
									</v-chip>

								</h2>
							</v-col>
						</v-row>
						<v-row justify="center" style="margin:auto" v-if="objCalifornian !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<s-scanner-qr
									:config="configScann"
									return-object
									:autofocus="false"
									@onValue="onValue($event)"
									:readonly="false"

								></s-scanner-qr>
							</v-col>
						</v-row>
						<!-- <v-row justify="center"  v-if="objCalifornian !== null">
							<v-col cols="3" v-if="objCalifornian.inParihuelaUsage == 0">
								<s-select-definition 
									:def="1389" 
									label="Estado de Materia Prima" 
									clearable
									v-model="TypeStateMp" >
								</s-select-definition>
                                   
							</v-col>
						</v-row> -->


						<v-row style="margin:auto" justify="center">

							<v-col cols="12">
								<s-select
									:items="itemsCalifornian"
									item-value="ClfID"
									item-text="ClfName"
									label="Seleccionar Californiano"
									return-object
									
									autocomplete
									v-model="objCalifornian"
									:slotTmp="true"
								>
									<template v-slot:item="data">
										{{ data.item.ClfName }}
									<!--	<v-chip
											small
											:color="data.item.inColor"
											>{{
												data.item.ParihuelaAvailableUnit
											}}
										</v-chip>
-->
										<v-chip
											small
											class="ma-2"
											variant="outlined"
											:color="data.item.inCalifornianStateColor"
											><b>{{
												data.item.inParihuelaUsage
											}}</b>
										</v-chip>
										<v-chip
											small
											:color="data.item.inCalifornianStateColor"
											><b>{{
												data.item.inCalifornianStateName
											}}</b>
										</v-chip>

										

										
										
									</template>
								</s-select>
							</v-col>

						</v-row>
					</v-card>
				</div>
			</v-row>

			
		</v-container>

		<v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
	</v-card>
</template>

<script>
	import sTunnelMaduration from "../../../../services/FrozenProduction/TunnelMadurationService";
	import _sMaturationTunnelPallet from "@/services/FrozenProduction/MaturationTunnelPalletService";
	import signalr from "signalr";
	export default {
		components: {},
		data() {
			return {
				
				configScann: {},
				item: {},
				itemsCalifornian: [],
				objCalifornian: null,
				processing:false,
				messageProcessing: "",
				// TypeStateMp: 0,
			};
		},

		watch: {
		},

		created() {
			this.initialize()
		},

		methods: {

			initialize(){
				
				sTunnelMaduration.listCalifornian({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.ClfCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.ClfCapacity)- parseInt(element.inParihuelaUsage)
							
							
							//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 50 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 50){
								element.inColor = 'success'
							}
	
						});

						this.itemsCalifornian = r.data;
						console.log("al levantar", this.itemsCalifornian);

					}
					
				});
			},

			onValue(val) {
				this.verificateExist(val);
			},

			verificateExist(val)
			{
				let obj = {
					Origin: 3,
					LlpID: val
				} 
				_sMaturationTunnelPallet
				.verificarentry(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.countExist == resp.data;
						if(resp.data >= 1)
						{
							this.$fun.alert("El pallet ya esta ingresado, ¿Desea volver a registrarlo?", "question")
							.then(r =>{
								if(r.value)
								{
									this.save(val);
								}
							})
						}
						
						else{
							this.save(val);
						}
					}
				})
			},

			save(LlpID) {
				let item = {}
				if(this.objCalifornian.ParihuelaAvailableUnit == 0){
					this.$fun.alert("Error, Californiano se encuentra lleno. Por favor verifique", "warning")
					return;
				}
				
				item.LlpID  = parseInt(LlpID)
				item.ClfID = this.objCalifornian.ClfID;
				item.SecStatus = 1
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();


				// item.TypeStateMp = this.TypeStateMp; //samir

				// if(this.objCalifornian.inParihuelaUsage == 0){
				// 	if (this.TypeStateMp == null ||  this.TypeStateMp == 0) {
				// 		this.$fun.alert("Por Favor Ingrese el Estado de la Materia Prima", "warning");
				// 		return;
				// 	}
				// }


				console.log("Guardar", item);
				

				// this.$fun.alert("Seguro de Ingrear Parihuela: " + LlpID + ", a: " + this.objCalifornian.ClfName, "question").then(val => {
				// 	if(val.value){
						this.processing = true;
						_sMaturationTunnelPallet
							.saveCalifornian(item, this.$fun.getUserID())
							.then(resp => {
								if (resp.status == 200) {
									this.processing = false;
									this.$fun.alert("Parihuela ingresada correctamente", "success");
									this.initialize()
								}
							},
							(e) => {
								this.processing = false;
							});
					// }
				// })
			},

		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
	};
</script>









			save(val) {
				
				var message = "";
				var messageSave = "";

				if (!this.Exit) {
					this.item.MtpID = 0;
					this.item.LlpID = val;
					this.item.TypeMaturation = 1;
					this.item.MtpStatus = 1;
					this.item.SecStatus = 1;
					this.item.UsrCreateID = this.$fun.getUserID();
					this.item.UsrUpdateID = this.$fun.getUserID();

					message = "Parihuela ingresado correctamente";
					messageSave = "Seguro de ingresar Parihuela  ==> " + val + " ?";
				} else {
					this.item.MtpID = val;
					this.item.SecStatus = 1;
					this.item.MtpStatus = 2;
					this.item.UsrUpdateID = this.$fun.getUserID();
					message = "Parihuela recogido correctamente";
					messageSave = "Seguro de recoger Parihuela ==> " + val;
				}

				console.log("save", this.item);
				this.$fun.alert(messageSave, "question").then(resp => {
					if (resp.value) {
						if (!this.Exit) {
							if (this.item.MtpTemperatureBegin == "") {
								this.$fun.alert(
									"Ingrese temperatura entrada",
									"warning"
								);
								return;
							}

							if (this.item.MtpEthyleneHour == "") {
								this.$fun.alert("Ingrese horas etileno", "warning");
								return;
							}
						} else {
							if (this.item.MtpTemperatureEnd == "") {
								this.$fun.alert(
									"Ingrese una temperatura de salida",
									"warning"
								);
								return;
							}
						}

						_sMaturationTunnelPalletService
							.save(this.item, this.$fun.getUserID())
							.then(resp => {
								if (resp.status == 200) {
									this.$fun.alert(message, "success");

									this.item.MtpID = 0;
									this.item.MtpTemperatureEnd = "";
									this.item.MtpEthyleneHour = "";
									this.item.MtpTemperatureBegin = "";
								}
							});
					}
				});
			},




							<!-- Salida -->


							
							<!-- <v-col cols="12" lg="4" md="4" v-if="Exit">
								<s-text
									v-model="item.MtpTemperatureEnd"
									label="Temperatura Salida"
									number
									><template v-slot:append>
										<i
											style="color: red; font-size: 9px"
											class="fa fa-asterisk"
										></i> </template
								></s-text>
							</v-col> -->

							<!-- Entrada -->
							<!-- <v-col cols="12" lg="12" md="12" v-if="!Exit">
								<s-select-definition
									:def="1335"
									label="Destinos"
									v-model="item.FtmID"
								></s-select-definition>
							</v-col>
 -->
							<!-- <v-col cols="12" lg="2" md="4" v-if="!Exit">
								<s-text
									v-model="item.MtpTemperatureBegin"
									label="Temperatura Entrada"
									number
									><template v-slot:append>
										<i
											style="color: red; font-size: 9px"
											class="fa fa-asterisk"
										></i> </template
								></s-text>
							</v-col>
							<v-col cols="12" lg="2" md="4" v-if="!Exit">
								<s-text
									v-model="item.MtpEthyleneHour"
									label="Horas Etileno"
									number
									><template v-slot:append>
										<i
											style="color: red; font-size: 9px"
											class="fa fa-asterisk"
										></i> </template
								></s-text>
							</v-col> -->
						</v-row>
					</v-card>
				<!-- <v-row style="margin-top: 10px">
					
					<v-col cols="3">
						<b style="color: red">
							Campos Obligatorios (*)</b
						>
						
						
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="2">
						<b >
							{{Exit == false ?'Ingreso de Parihuela' :'Salida de Parihuela'}}</b
						>
						
						
					</v-col>
				</v-row>
					<v-col cols="12">
						<ul>
							<li>1.- Ingrese campos obligatorios</li>
							<li>2.- Clic en icono de Camara</li>
							<li>3.- Escanee codigo QR</li>
						</ul>
					</v-col> -->
