<template v-slot:no-data>
	<v-card dense>
		<v-row style="margin-top: 4px;">
			<v-col cols="12" lg="12" md="12">
				<v-tabs v-model="currentItem">
					
					<v-tab href="#EntryTunnel"> Ingreso Túnel</v-tab>
					<v-tab href="#EntryChamber"> Ingreso Cámara</v-tab>
					<v-tab href="#EntryCalifornian"> Ingreso Californiano</v-tab>
					<v-tab href="#EntryEnvironment"> Ingreso Plataforma</v-tab>
					
				</v-tabs>

				<v-tabs-items v-model="currentItem">
					<!-- 
 -->
					<v-tab-item :value="'EntryTunnel'">
                        <entry-tunnel />
					</v-tab-item>

					<v-tab-item :value="'EntryChamber'">
						<enter-chamber />
					</v-tab-item>

					<v-tab-item :value="'EntryCalifornian'">
						<enter-californian />
					</v-tab-item>

					<v-tab-item :value="'EntryEnvironment'">
						<enter-environment />
					</v-tab-item>


				</v-tabs-items>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>

	//Component
	import EntryTunnel from './FrzEnterTunnelQR.vue'
    import EnterChamber from './FrzEnterChamberQR.vue'
	import EnterCalifornian from './FrzEnterCalifornianQR.vue'
	import EnterEnvironment from './FrzEnterEnvironmentQR.vue'

	export default {
		components: {EntryTunnel, EnterChamber, EnterCalifornian, EnterEnvironment},
		props: {},
		data: () => ({
			currentItem: "tab-Funciones",
		}),
	};
</script>
